import createTsdApi from "../../createTsdApi";
import {
  Staff,
  StaffCreateParams,
  StaffQueryParams,
  StaffUpdateParams,
} from "../../../entities/staff";
import { TsdSearchParam } from "../fetch";

const staffApi = createTsdApi<
  Staff,
  StaffQueryParams,
  StaffCreateParams,
  StaffUpdateParams
>("staff", (searchParamsObject) => {
  let searchParams: TsdSearchParam<StaffQueryParams>[] = [];

  if (typeof searchParamsObject.isArchived !== "undefined") {
    searchParams.push({
      key: "isArchived",
      value: searchParamsObject.isArchived ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.userIds !== "undefined") {
    searchParamsObject.userIds.forEach((id) => {
      searchParams.push({
        key: "userIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.name !== "undefined") {
    searchParams.push({
      key: "name",
      value: searchParamsObject.name,
    });
  }

  if (typeof searchParamsObject.teamsIds !== "undefined") {
    searchParamsObject.teamsIds.forEach((teamId) => {
      searchParams.push({
        key: "teamsIds",
        value: teamId,
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.orderByName !== "undefined") {
    searchParams.push({
      key: "orderByName",
      value: searchParamsObject.orderByName,
    });
  }

  if (typeof searchParamsObject.approvalUserIds !== "undefined") {
    searchParamsObject.approvalUserIds.map((id) =>
      searchParams.push({ key: "approvalUserIds", value: String(id) })
    );
  }

  return searchParams;
});

export default staffApi;
